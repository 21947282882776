import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <div>
            <footer>
                <div className="footerSec">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="footer-contact">
                                    <div className="footer-logo">
                                        <img src="images/ftr-logo.png" alt="img" />
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-12">
                                <div className="foot-links">
                                    <h5>Quick Links</h5>
                                    <ul className="f-nav">
                                        <li><Link to="/">HOME</Link></li>
                                        <li><Link to="/products">PRODUCTS</Link></li>
                                        <li><Link to="/advertisement">ADVERTISEMENT</Link></li>
                                        <li><Link to="/signup">ACCOUNT</Link></li>
                                        <li><Link to="/contact">CONTACT US</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="foot-links">
                                    <h5>Contact Info</h5>
                                    <ul className="f-contact">
                                        {/* <li><Link to="tel:021 5823 132588"><i class="far fa-phone-alt" aria-hidden="true"></i><p> 021 5823 132588</p> </Link></li> */}
                                        <li>
                                            <Link to="loreumipsuem@gmail.com">
                                                <i className="fa-solid fa-envelope" />
                                                <p>loreumipsuem@gmail.com</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="foot-links">
                                    <h5>NewsLetter</h5>
                                    <p>Stay up to update with our latest news and products.</p>
                                    <div className="f-input">
                                        <input type="email" name placeholder="Your Email Address" />
                                        <button type="submit" className="btn-1 btn2">SUBSCRIBE NOW</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-btm">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-12">
                                    <div className="copy-txt">
                                        <Link to="/terms-and-conditons">TERMS AND CONDITIONS</Link>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4">
                                    <div className="copy-txt">
                                        <p> Copyright © 2024 . All Rights Reserved. </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-12 text-end">
                                    <div className="copy-txt">
                                        <Link to="/privacy-policy">PRIVACY POLICY</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
