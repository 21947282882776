import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

export default function Home() {

    useEffect(() => {
        function timerStart() {

            expiringDate = new Date();
            expiringDate.setDate(expiringDate.getDate() + 11);

            intervalId = setInterval(tick, 1000);
        }

        function calculateRemainingTime() {

            let currentDate = new Date();
            let remainingTime = expiringDate.getTime() - currentDate.getTime();

            if (remainingTime < 0) return null;

            let dateObject = {};

            dateObject.days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
            dateObject.hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            dateObject.minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
            dateObject.seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

            return dateObject;
        }

        function refreshTimer(dateObject) {

            if (!dateObject) {
                daysId.textContent = 0;
                hoursId.textContent = 0;
                minutesId.textContent = 0;
                secondsId.textContent = 0;
                clearInterval(intervalId);
            }

            daysId.textContent = dateObject.days;
            hoursId.textContent = dateObject.hours;
            minutesId.textContent = dateObject.minutes;
            secondsId.textContent = dateObject.seconds;

        }

        function tick() {

            let dateObject = calculateRemainingTime();
            refreshTimer(dateObject);

        }

        let expiringDate;
        let intervalId;

        let daysId = document.getElementById('days');
        let hoursId = document.getElementById('hours');
        let minutesId = document.getElementById('minutes');
        let secondsId = document.getElementById('seconds');

        timerStart()
    })


    var banner = {
        dots: true,
        arrows: false,
        rtl: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    var categories = {
        dots: true,
        arrows: false,
        rtl: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 1
    };
    return (
        <div>
            <section className="banner">
                <div className="container">
                    <div className="bann-slid wow fadeInRightBig" data-wow-duration="2s">
                        <Slider {...banner}>
                            <div>
                                <div className="row align-items-center">
                                    <div className="col-md-4 col-lg-4 col-12">
                                        <div className="bann-txt wow fadeInLeftBig" data-wow-duration="2s">
                                            <h3>MODEL X</h3>
                                            <h2>THE BEST SUV</h2>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsumet, consectetur</p>
                                            <Link to="/advertisement" className="btn-1"> Post AN ADD </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-12">
                                        <div className="banner-img">
                                            <img src="images/bann-1.png" alt />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="row align-items-center">
                                    <div className="col-md-4 col-lg-4 col-12">
                                        <div className="bann-txt wow fadeInLeftBig" data-wow-duration="2s">
                                            <h3>MODEL X</h3>
                                            <h2>THE BEST SUV</h2>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsumet, consectetur</p>
                                            <Link to="/advertisement" className="btn-1"> FIND SOMETHING NEW </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-8 col-12">
                                        <div className="banner-img">
                                            <img src="images/bann-2.png" alt />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                    <div className="head-3">
                        <h3>TESTLA</h3>
                    </div>
                </div>
            </section>
            <section className="categories">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-12">
                            <div className="gate-txt wow fadeInDown" data-wow-duration="2s">
                                <h3>CATEGORIES</h3>
                            </div>
                            <div className="cate-slid">
                                <Slider {...categories}>
                                    <div>
                                        <div className="cate-main-bx">
                                            <Link to=""><img src="images/cat-1.png" alt /></Link>
                                            <h5>CARS &amp; VEHICLES</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cate-main-bx">
                                            <Link to=""><img src="images/cat-2.png" alt /></Link>
                                            <h5>JOBS</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cate-main-bx">
                                            <Link to=""><img src="images/cat-3.png" alt /></Link>
                                            <h5>PROPRERTY</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cate-main-bx">
                                            <Link to=""><img src="images/cat-4.png" alt /></Link>
                                            <h5>CARPENTERS</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cate-main-bx">
                                            <Link to=""><img src="images/cat-5.png" alt /></Link>
                                            <h5>HEALTH &amp; BEAUTY</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cate-main-bx">
                                            <Link to=""><img src="images/cat-6.png" alt /></Link>
                                            <h5>HEADPHONES</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cate-main-bx">
                                            <Link to=""><img src="images/cat-7.png" alt /></Link>
                                            <h5>KIDS STUFF</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cate-main-bx">
                                            <Link to=""><img src="images/cat-8.png" alt /></Link>
                                            <h5>REFRIGERATOR</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cate-main-bx">
                                            <Link to=""><img src="images/cat-1.png" alt /></Link>
                                            <h5>CARS &amp; VEHICLES</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cate-main-bx">
                                            <Link to=""><img src="images/cat-2.png" alt /></Link>
                                            <h5>JOBS</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cate-main-bx">
                                            <Link to=""><img src="images/cat-3.png" alt /></Link>
                                            <h5>PROPRERTY</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="cate-main-bx">
                                            <Link to=""><img src="images/cat-4.png" alt /></Link>
                                            <h5>CARPENTERS</h5>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="Despired-car">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-12">
                            <div className="des-txt wow fadeInLeftBig" data-wow-duration="2s">
                                <h4>FIND YOUR DESIRED CARS</h4>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-12 text-end wow fadeInRightBig" data-wow-duration="2s">
                            <Link to="" className="btn-1">MORE DETAIL</Link>
                        </div>
                        <div className="col wow fadeInLeft" data-wow-duration="2s">
                            <div className="main-car-bx">
                                <div className="car-img">
                                    <Link to="/post-add"><img src="images/car-1.png" alt /></Link>
                                </div>
                                <div className="car-txt">
                                    <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                    <ul>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                    <div className="check">
                                        <i className="fas fa-check" />
                                        <h4>AVAILABLE</h4>
                                    </div>
                                    <Link to="" className="btn-1">GET A QUOTE</Link>
                                    <p>SKU:<span>30876</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="col wow fadeInRight" data-wow-duration="2s">
                            <div className="main-car-bx">
                                <div className="car-img">
                                    <Link to="/post-add"><img src="images/car-2.png" alt /></Link>
                                </div>
                                <div className="car-txt">
                                    <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                    <ul>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                    <div className="check">
                                        <i className="fas fa-check" />
                                        <h4>AVAILABLE</h4>
                                    </div>
                                    <Link to="" className="btn-1">GET A QUOTE</Link>
                                    <p>SKU:<span>30876</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="col wow fadeInLeft" data-wow-duration="2s">
                            <div className="main-car-bx">
                                <div className="car-img">
                                    <Link to="/post-add"><img src="images/car-3.png" alt /></Link>
                                </div>
                                <div className="car-txt">
                                    <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                    <ul>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                    <div className="check">
                                        <i className="fas fa-check" />
                                        <h4>AVAILABLE</h4>
                                    </div>
                                    <Link to="" className="btn-1">GET A QUOTE</Link>
                                    <p>SKU:<span>30876</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="col wow fadeInRight" data-wow-duration="2s">
                            <div className="main-car-bx">
                                <div className="car-img">
                                    <Link to="/post-add"><img src="images/car-4.png" alt /></Link>
                                </div>
                                <div className="car-txt">
                                    <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                    <ul>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                    <div className="check">
                                        <i className="fas fa-check" />
                                        <h4>AVAILABLE</h4>
                                    </div>
                                    <Link to="" className="btn-1">GET A QUOTE</Link>
                                    <p>SKU:<span>30876</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="col wow fadeInLeft" data-wow-duration="2s">
                            <div className="main-car-bx">
                                <div className="car-img">
                                    <Link to="/post-add"><img src="images/car-5.png" alt /></Link>
                                </div>
                                <div className="car-txt">
                                    <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                    <ul>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                    <div className="check">
                                        <i className="fas fa-check" />
                                        <h4>AVAILABLE</h4>
                                    </div>
                                    <Link to="" className="btn-1">GET A QUOTE</Link>
                                    <p>SKU:<span>30876</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-lg-4 col-md-4 col-12 wow fadeInRight" data-wow-duration="2s">
                                <div className="car-main-bsx">
                                    <div className="car-tas">
                                        <h6>AT A GOOD PRICE</h6>
                                        <h3>TESLA MODEL X</h3>
                                        <Link to="" className="btn-1">BUY NOW</Link>
                                    </div>
                                    <div className="car-tas-img">
                                        <img src="images/car-tas.png" alt />
                                    </div>
                                    <div className="tas-txt-bac">
                                        <h3>TESTLA</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="new-good">
                                            <h4>NEW GOODS</h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12 text-end">
                                        <Link to="" className="btn-1">MORE PRODUCTS</Link>
                                    </div>
                                    <div className="col">
                                        <div className="main-car-bx">
                                            <div className="car-img">
                                                <Link to="/post-add"><img src="images/car-6.png" alt /></Link>
                                            </div>
                                            <div className="car-txt">
                                                <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                                <ul>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                </ul>
                                                <div className="check">
                                                    <i className="fas fa-check" />
                                                    <h4>AVAILABLE</h4>
                                                </div>
                                                <Link to="" className="btn-1">GET A QUOTE</Link>
                                                <p>SKU:<span>30876</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="main-car-bx">
                                            <div className="car-img">
                                                <Link to="/post-add"><img src="images/car-7.png" alt /></Link>
                                            </div>
                                            <div className="car-txt">
                                                <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                                <ul>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                </ul>
                                                <div className="check">
                                                    <i className="fas fa-check" />
                                                    <h4>AVAILABLE</h4>
                                                </div>
                                                <Link to="" className="btn-1">GET A QUOTE</Link>
                                                <p>SKU:<span>30876</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="main-car-bx">
                                            <div className="car-img">
                                                <Link to="/post-add"><img src="images/car-8.png" alt /></Link>
                                            </div>
                                            <div className="car-txt">
                                                <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                                <ul>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                </ul>
                                                <div className="check">
                                                    <i className="fas fa-check" />
                                                    <h4>AVAILABLE</h4>
                                                </div>
                                                <Link to="" className="btn-1">GET A QUOTE</Link>
                                                <p>SKU:<span>30876</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="main-car-bx">
                                            <div className="car-img">
                                                <Link to="/post-add"><img src="images/car-9.png" alt /></Link>
                                            </div>
                                            <div className="car-txt">
                                                <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                                <ul>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                    <li><Link to=""><i className="fas fa-star" /></Link></li>
                                                </ul>
                                                <div className="check">
                                                    <i className="fas fa-check" />
                                                    <h4>AVAILABLE</h4>
                                                </div>
                                                <Link to="" className="btn-1">GET A QUOTE</Link>
                                                <p>SKU:<span>30876</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="dream-house">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5 col-lg-5 col-12">
                            <div className="house-img wow fadeInLeftBig" data-wow-duration="2s">
                                <img src="images/house.png" alt />
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-12">
                            <div className="house-txt wow fadeInRightBig" data-wow-duration="2s">
                                <h4>FIND YOUR DREAM HOME</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut</p>
                                <div id="timer">
                                    <div className="days-wrapper wrapper">
                                        <span id="days" className="date">13</span>
                                        <span className="label">
                                            Days
                                        </span>
                                    </div>
                                    <div className="hours-wrapper wrapper">
                                        <span id="hours" className="date">15</span>
                                        <span className="label">
                                            Hours
                                        </span>
                                    </div>
                                    <div className="minutes-wrapper wrapper">
                                        <span id="minutes" className="date">44</span>
                                        <span className="label">
                                            Mins
                                        </span>
                                    </div>
                                    <div className="seconds-wrapper wrapper">
                                        <span id="seconds" className="date">09</span>
                                        <span className="label">
                                            Secs
                                        </span>
                                    </div>
                                </div>
                                <Link to="" className="btn-1">DISCOVER NOW</Link>
                            </div>
                        </div>
                        <div className="col wow fadeInDown" data-wow-duration="2s">
                            <div className="house-sm-bx">
                                <ul>
                                    <li><img src="images/house-sm-1.jpg" alt /></li>
                                    <li><h6>PRODUCTS NAME HERE </h6><ul>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                        <h4>AVAILABLE</h4>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col wow fadeInLeft" data-wow-duration="2s">
                            <div className="house-sm-bx">
                                <ul>
                                    <li><img src="images/house-sm-2.jpg" alt /></li>
                                    <li><h6>PRODUCTS NAME HERE </h6><ul>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                        <h4>AVAILABLE</h4>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col wow fadeInRight" data-wow-duration="2s">
                            <div className="house-sm-bx">
                                <ul>
                                    <li><img src="images/house-sm-3.jpg" alt /></li>
                                    <li><h6>PRODUCTS NAME HERE </h6><ul>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                        <h4>AVAILABLE</h4>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col wow fadeInUp" data-wow-duration="2s">
                            <div className="house-sm-bx">
                                <ul>
                                    <li><img src="images/house-sm-4.jpg" alt /></li>
                                    <li><h6>PRODUCTS NAME HERE </h6><ul>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                        <h4>AVAILABLE</h4>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col wow fadeInRight" data-wow-duration="2s">
                            <div className="house-sm-bx">
                                <ul>
                                    <li><img src="images/house-sm-5.jpg" alt /></li>
                                    <li><h6>PRODUCTS NAME HERE </h6><ul>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to="" /></li><li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                        <h4>AVAILABLE</h4>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="accessories-sec all-section">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                            <div className="accessories-txt-main wow fadeInLeft" data-wow-duration="2s">
                                <div className="accessories-txt">
                                    <h3>microsoft accessories</h3>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                                    </p>
                                    <div className="accessories-txt-bottom">
                                        <ul>
                                            <li>
                                                <img src="images/accessories-bo1.png" alt="img" />
                                                <h5>
                                                    Keyboard
                                                </h5>
                                            </li>
                                            <li>
                                                <img src="images/accessories-bo2.png" alt="img" />
                                                <h5>
                                                    Surface Pen
                                                </h5>
                                            </li>
                                            <li>
                                                <img src="images/accessories-bo3.png" alt="img" />
                                                <h5>
                                                    Mices
                                                </h5>
                                            </li>
                                            <li>
                                                <img src="images/accessories-bo4.png" alt="img" />
                                                <h5>
                                                    Headephones
                                                </h5>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accessories-card-main wow fadeInRight" data-wow-duration="2s">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
                                        <div className="accessories-card wow fadeInLeft" data-wow-duration="2s">
                                            <div className="accessories-card-txt-main">
                                                <div className="accessories-card-txt">
                                                    <h5>Products Name Here</h5>
                                                    <p>
                                                        Discount Up To 30%
                                                    </p>
                                                </div>
                                                <div className="accessories-card-btn">
                                                    <Link to="" className="btn-1">
                                                        Get A Quote
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="accessories-card-img">
                                                <img src="images/accessories-card1.png" alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
                                        <div className="accessories-card wow fadeInRight" data-wow-duration="2s">
                                            <div className="accessories-card-txt-main">
                                                <div className="accessories-card-txt">
                                                    <h5>Products Name Here</h5>
                                                    <p>
                                                        Discount Up To 30%
                                                    </p>
                                                </div>
                                                <div className="accessories-card-btn">
                                                    <Link to="" className="btn-1">
                                                        Get A Quote
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="accessories-card-img">
                                                <img src="images/accessories-card2.png" alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
                                        <div className="accessories-card">
                                            <div className="accessories-card-txt-main">
                                                <div className="accessories-card-txt">
                                                    <h5>Products Name Here</h5>
                                                    <p>
                                                        Discount Up To 30%
                                                    </p>
                                                </div>
                                                <div className="accessories-card-btn">
                                                    <Link to="" className="btn-1">
                                                        Get A Quote
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="accessories-card-img">
                                                <img src="images/accessories-card3.png" alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                            <div className="accessories-product-main wow fadeInLeft" data-wow-duration="2s">
                                <div className="accessories-product-img">
                                    <img src="images/accessories-product1.jpg" alt="img" />
                                </div>
                                <div className="accessories-product-txt">
                                    <h5>Products Name Here</h5>
                                    <p>Cars Model</p>
                                    <div className="accessories-star">
                                        <ul>
                                            <li>
                                                <Link to="">
                                                    <i className="fas fa-star" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="">
                                                    <i className="fas fa-star" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="">
                                                    <i className="fas fa-star" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="">
                                                    <i className="fas fa-star" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="">
                                                    <i className="fas fa-star" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="accessories-status">
                                        <ul>
                                            <li>
                                                <i className="fal fa-check" />
                                            </li>
                                            <li>
                                                <div className="accessories-status-txt"><h5>Available</h5></div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="accessories-btn">
                                        <Link to="" className="btn-1">
                                            Get A Quote
                                        </Link>
                                    </div>
                                    <div className="accessories-bottom">
                                        <h6>SKU: <span>30876</span></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="unlock">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7 col-lg-7 col-12"></div>
                        <div className="col-md-5 col-lg-5 col-12">
                            <div className="unlock-txt-box wow fadeInRightBig" data-wow-duration="2s">
                                <h4>UNLOCK <span>ADVERTISING OPORTUNITIES</span></h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="perfect wow fadeInDownBig" data-wow-duration="2s">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-12">
                            <div className="des-txt">
                                <h4>THE PERFECT FUSION OF HEALTH AND BEAUTY</h4>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-12 text-end">
                            <Link to="" className="btn-1">MORE DETAIL</Link>
                        </div>
                        <div className="col">
                            <div className="main-car-bx">
                                <div className="car-img">
                                    <img src="images/pro-1.png" alt />
                                </div>
                                <div className="car-txt">
                                    <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                    <ul>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                    <div className="check">
                                        <i className="fas fa-check" />
                                        <h4>AVAILABLE</h4>
                                    </div>
                                    <Link to="" className="btn-1">GET A QUOTE</Link>
                                    <p>SKU:<span>30876</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="main-car-bx">
                                <div className="car-img">
                                    <img src="images/pro-2.png" alt />
                                </div>
                                <div className="car-txt">
                                    <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                    <ul>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                    <div className="check">
                                        <i className="fas fa-check" />
                                        <h4>AVAILABLE</h4>
                                    </div>
                                    <Link to="" className="btn-1">GET A QUOTE</Link>
                                    <p>SKU:<span>30876</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="main-car-bx">
                                <div className="car-img">
                                    <img src="images/pro-3.png" alt />
                                </div>
                                <div className="car-txt">
                                    <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                    <ul>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                    <div className="check">
                                        <i className="fas fa-check" />
                                        <h4>AVAILABLE</h4>
                                    </div>
                                    <Link to="" className="btn-1">GET A QUOTE</Link>
                                    <p>SKU:<span>30876</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="main-car-bx">
                                <div className="car-img">
                                    <img src="images/pro-4.png" alt />
                                </div>
                                <div className="car-txt">
                                    <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                    <ul>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                    <div className="check">
                                        <i className="fas fa-check" />
                                        <h4>AVAILABLE</h4>
                                    </div>
                                    <Link to="" className="btn-1">GET A QUOTE</Link>
                                    <p>SKU:<span>30876</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="main-car-bx">
                                <div className="car-img">
                                    <img src="images/pro-5.png" alt />
                                </div>
                                <div className="car-txt">
                                    <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                    <ul>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                        <li><Link to=""><i className="fas fa-star" /></Link></li>
                                    </ul>
                                    <div className="check">
                                        <i className="fas fa-check" />
                                        <h4>AVAILABLE</h4>
                                    </div>
                                    <Link to="" className="btn-1">GET A QUOTE</Link>
                                    <p>SKU:<span>30876</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
