import React from 'react'

export default function Signup() {
    return (
        <div>
            <section className="banner inn">
                <div className="container">
                    <div className="bann-slid wow fadeInRightBig" data-wow-duration="2s">
                        <div>
                            <div className="row align-items-center">
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="bann-txt wow fadeInLeftBig" data-wow-duration="2s">
                                        <h2>ACCOUNT</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsumet, consectetur</p>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-12">
                                    <div className="banner-img">
                                        <img src="images/banner-img.png" alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="head-3">
                        <h3>TESTLA</h3>
                    </div>
                </div>
            </section>
            <section className="signin_sec">
                <div className="container">
                    <div className="row align-items-center bg-from-clr">
                        <div className="col-lg-7">
                            <div className="social_btn">
                                <h3>SIGN UP</h3>
                            </div>
                            <form action="#" className="signup_form">
                                <div>
                                    <div className="input-icons">
                                        <i className="fa fa-user icon" aria-hidden="true" />
                                        <input className="input-field" type="text" placeholder="Your Name" />
                                        <i className="fa fa-envelope icon" aria-hidden="true" />
                                        <input className="input-field" type="email" placeholder="Your Email" />
                                        <i className="fas fa-lock icon" aria-hidden="true" />
                                        <input className="input-field" type="password" placeholder="Your Password" />
                                        <i className="fas fa-lock icon" aria-hidden="true" />
                                        <input className="input-field" type="password" placeholder="Confirm Password" />
                                    </div>
                                    <div className="btn-wrapper">
                                        <button className="btn-2" type="submit">Sign Up</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-lg-5">
                            <div className="login">
                                <h3>LOGIN</h3>
                                <form action="#" className="login_form">
                                    <div className="input-icons">
                                        <i className="fa fa-envelope icon" aria-hidden="true" />
                                        <input className="input-field" type="email" placeholder="Your Email" />
                                        <i className="fas fa-lock icon" aria-hidden="true" />
                                        <input className="input-field" type="password" placeholder="Your Password" />
                                        <div className="remeber">
                                            <input type="checkbox" />
                                            <label htmlFor="remeber">Remeber me</label>
                                        </div>
                                    </div>
                                    <div className="forgot_pass">
                                        <a href="#">Forgot Password?</a>
                                        <div className="btn-wrapper">
                                            <button className="btn-2" type="submit">Login</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
