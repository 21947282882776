import React from 'react'

export default function Contact() {
    return (
        <div>
            <section className="banner inn">
                <div className="container">
                    <div className="bann-slid wow fadeInRightBig" data-wow-duration="2s">
                        <div>
                            <div className="row align-items-center">
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="bann-txt wow fadeInLeftBig" data-wow-duration="2s">
                                        <h2>CONTACT US</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsumet, consectetur</p>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-12">
                                    <div className="banner-img">
                                        <img src="images/banner-img.png" alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="head-3">
                        <h3>TESTLA</h3>
                    </div>
                </div>
            </section>
            <section className="contact-sec all-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-md-11 centerCol">
                            <div className="contact-box">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="contact-text">
                                            <h6>Get In Touch</h6>
                                            <h3>Get In Touch</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt </p>
                                            {/* <a href="telto:(023)-5183-2183">(023) - 5183 - 2183</a> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="contact-form">
                                            <form action>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="cont-input">
                                                            <input type="text" name="name" id placeholder="NAME" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="cont-input">
                                                            <input type="email" name="email" id placeholder="EMAIL ADDRESS" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="cont-input">
                                                            <textarea name="message" id cols={30} rows={10} placeholder="MESSAGE" defaultValue={""} />
                                                        </div>
                                                    </div>
                                                    <div className="contact-button">
                                                        <button type="submit" className="btn1 btn2">Submit Now</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {/*   <div class="col-lg-3 col-md-4">
                                  <ul class="contact-icons">
                                      <li><img src="images/con-1.png" alt="" class="con-1" /></li>
                                      <li>
                                          <a href="tel:(00) 123 456 7890">
                                              <p>Tel: (00) 123 456 7890</p>
                                          </a>
                                          <a href="tel:(00) 123 456 7890">
                                              <p>Fax: (00) 123 456 7890 </p>
                                          </a>
                                      </li>
                                  </ul>
                              </div> */}
                                    <div className="col-lg-6 col-md-4">
                                        <ul className="contact-icons center-box">
                                            <li><img src="images/con-2.png" alt className="con-2" /></li>
                                            <li>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do ei.</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-3 col-md-4">
                                        <ul className="contact-icons">
                                            <li><img src="images/con-3.png" alt className="con-3" /></li>
                                            <li>
                                                <a href="mailto:example@gmai.com">
                                                    <p>example@gmai.com</p>
                                                </a>
                                                <a href="mailto:example@gmai.com">
                                                    <p>example@gmai.com</p>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="contact-map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3838.3127222869352!2d-115.20379999240218!3d36.13570091039572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c8c6acd8cf5203%3A0x353d189adea1fcb0!2sClark%20High%20School!5e0!3m2!1sen!2s!4v1685971844921!5m2!1sen!2s" width={600} height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
