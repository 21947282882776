import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, useLocation, Routes } from "react-router-dom";
import Header from './components/Header';
import Home from './pages/Home';
import Footer from './components/Footer';
import './App.css';
import Signup from './pages/Signup';
import Products from './pages/Products';
import Postadd from './pages/Postadd';
import Advertisement from './pages/Advertisement';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
// import Loader from './components/Loader';
import { AnimatePresence, motion } from 'framer-motion';

// Reusable MotionWrapper component for animations
const MotionWrapper = ({ children }) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: -20, scale: 1.5 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 20, scale: 1.5 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
        >
            {children}
        </motion.div>
    );
};

function App() {
    // const [loading, setLoading] = useState(false);
    const location = useLocation();

    // Simulate loading state (can be based on real loading logic)
    // useEffect(() => {
    //     setLoading(true);
    //     const timer = setTimeout(() => setLoading(false), 500); // Simulating a loading delay
    //     return () => clearTimeout(timer);
    // }, [location]);

    return (
        <>
            {/* {loading && <Loader />} */}
            <AnimatePresence>
                <Header />
                <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<MotionWrapper><Home /></MotionWrapper>} />
                    <Route path="/signup" element={<MotionWrapper><Signup /></MotionWrapper>} />
                    <Route path="/products" element={<MotionWrapper><Products /></MotionWrapper>} />
                    <Route path="/post-add" element={<MotionWrapper><Postadd /></MotionWrapper>} />
                    <Route path="/advertisement" element={<MotionWrapper><Advertisement /></MotionWrapper>} />
                    <Route path="/contact" element={<MotionWrapper><Contact /></MotionWrapper>} />
                    <Route path="/terms-and-conditions" element={<MotionWrapper><Terms /></MotionWrapper>} />
                    <Route path="/privacy-policy" element={<MotionWrapper><Privacy /></MotionWrapper>} />
                </Routes>
                <Footer />
            </AnimatePresence>
        </>
    );
}

const WrappedApp = () => (
    <Router>
        <App />
    </Router>
);

export default WrappedApp;
