import React, { useState } from 'react'
import Slider from 'react-slick'

export default function Postadd() {

    const [mainSlider, setMainSlider] = useState(null);
    const [navSlider, setNavSlider] = useState(null);

    var products = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        asNavFor: navSlider,
        slidesToScroll: 1,
    };
    var products_sml = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        focusOnSelect: true,
        asNavFor: mainSlider,
        slidesToScroll: 1,
    };

    return (
        <div>
            <section className="banner inn">
                <div className="container">
                    <div className="bann-slid wow fadeInRightBig" data-wow-duration="2s">
                        <div>
                            <div className="row align-items-center">
                                <div className="col-md-5 col-lg-5 col-12">
                                    <div className="bann-txt wow fadeInLeftBig" data-wow-duration="2s">
                                        <h2>POST ADD</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsumet, consectetur</p>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7 col-12">
                                    <div className="banner-img">
                                        <img src="images/banner-img.png" alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="head-3">
                        <h3>TESTLA</h3>
                    </div>
                </div>
            </section>
            <section className="product_details_area p_100">
                <div className="container">
                    <div className="row product_details_inner">
                        <div className="col-lg-8">
                            <div className="product_details_left">
                                <div className="price_title d-flex justify-content-between">
                                    <div className="left">
                                        <h3>La Ferrari Coupe</h3>
                                        <div className="d-flex">
                                            <div className="star-rating" />
                                            <a href="#review" className="review_link">324 reviews</a>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <h4>$16,000</h4>
                                    </div>
                                </div>
                                <div className="product_info">
                                    <a className="popup-with-zoom-anim" href="#getinfoPopup"><i className="icon-info1" />Request More Info</a>
                                    <a href="compare-car-step-1.html"><i className="icon-compare" />Add to Compare</a>
                                    <a className="popup-with-zoom-anim" href="#bookPopup"><i className="icon-steering" />Book a Test Drive</a>
                                    <a href="#"><i className="icon-share1" />Share this Car</a>
                                </div>
                                <div className="product_d_slider">
                                    <div className="product_main_slider">
                                        <Slider {...products} ref={(slider) => setMainSlider(slider)}>
                                            <div className="item">
                                                <img src="images/product-details-1.jpg" alt />
                                            </div>
                                            <div className="item">
                                                <img src="images/product-details-2.jpg" alt />
                                            </div>
                                            <div className="item">
                                                <img src="images/product-details-3.jpg" alt />
                                            </div>
                                            <div className="item">
                                                <img src="images/product-details-4.jpg" alt />
                                            </div>
                                            <div className="item">
                                                <img src="images/product-details-6.jpg" alt />
                                            </div>
                                            <div className="item">
                                                <img src="images/product-details-1.jpg" alt />
                                            </div>
                                            <div className="item">
                                                <img src="images/product-details-2.jpg" alt />
                                            </div>
                                            <div className="item">
                                                <img src="images/product-details-3.jpg" alt />
                                            </div>
                                        </Slider>
                                    </div>
                                    <div className="product_nav_slider">
                                        <Slider {...products_sml} ref={(slider) => setNavSlider(slider)}>
                                            <div className="item">
                                                <div className="img_inner">
                                                    <img src="images/product-tab-1.jpg" alt />
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="img_inner">
                                                    <img src="images/product-tab-2.jpg" alt />
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="img_inner">
                                                    <img src="images/product-tab-3.jpg" alt />
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="img_inner">
                                                    <img src="images/product-tab-4.jpg" alt />
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="img_inner">
                                                    <img src="images/product-tab-6.jpg" alt />
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="img_inner">
                                                    <img src="images/product-tab-1.jpg" alt />
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="img_inner">
                                                    <img src="images/product-tab-2.jpg" alt />
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="img_inner">
                                                    <img src="images/product-tab-3.jpg" alt />
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="product_list_right">
                                <a className="main_btn red popup-with-zoom-anim" href="#offerPopup">Make an Offer Price</a>
                                <a className="main_btn red popup-with-zoom-anim" href="#tradePopup">Trade in Form</a>
                                <ul className="nav flex-column">
                                    <li><a href="#"><i className="icon-clock_2" />Date <span>12 June 2019</span></a></li>
                                    <li><a href="#"><i className="icon-gear1" />Transmission <span>Automatic</span></a></li>
                                    <li><a href="#"><i className="icon-calendar-check-o" />Year <span>2019</span></a></li>
                                    <li><a href="#"><i className="icon-engine" />Engine Size <span>3200 cc</span></a></li>
                                    <li><a href="#"><i className="icon-car_3" />Body Type <span>Sedan</span></a></li>
                                    <li><a href="#"><i className="icon-engine" />Engine Type <span>Petrol</span></a></li>
                                    <li><a href="#"><i className="icon-assembly" />Assembly <span>Germany</span></a></li>
                                    <li><a href="#"><i className="icon-seat" />Seat <span>5</span></a></li>
                                    <li><a href="#"><i className="icon-color_plate" />Color <span className="color" /></a></li>
                                    <li><a href="#"><i className="icon-door" />Doors <span>4</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="product_overview_text">
                        <h4>Overview</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do them and eiusmod tempor incididunt labore dolorie magna aliqua. Ut enim adoren minim venim quis nostrud exercitation. Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat</p>
                        <div className="row">
                            <div className="col-lg-6">
                                <ul className="nav flex-column">
                                    <li><img src="images/green.png" alt />Strong engine options, including a plug-in hybrid</li>
                                    <li><img src="images/green.png" alt />Standard adaptive air suspension delivers a comfortable</li>
                                    <li><img src="images/green.png" alt />Exceptional rear passenger space</li>
                                    <li><img src="images/green.png" alt />Abundant standard equipment, including safety tech</li>
                                    <li><img src="images/green.png" alt />No standard-length wheelbase model available</li>
                                    <li><img src="images/green.png" alt />Not the driver-focused benchmark it once was</li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <ul className="nav flex-column">
                                    <li><img src="images/green.png" alt />Strong engine options, including a plug-in hybrid</li>
                                    <li><img src="images/green.png" alt />Standard adaptive air suspension delivers a comfortable</li>
                                    <li><img src="images/green.png" alt />Exceptional rear passenger space</li>
                                    <li><img src="images/green.png" alt />Abundant standard equipment, including safety tech</li>
                                    <li><img src="images/green.png" alt />No standard-length wheelbase model available</li>
                                    <li><img src="images/green.png" alt />Not the driver-focused benchmark it once was</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="specification_area">
                        <div className="single_b_title">
                            <h3>Specification</h3>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="left_spec">
                                    <div className="nav nav-tabs flex-column" id="nav-tab" role="tablist">
                                        <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Performance &amp; Efficiency</a>
                                        <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Audio System</a>
                                        <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Instrumentation &amp; Controls</a>
                                        <a className="nav-item nav-link" id="nav-contact-tab2" data-toggle="tab" href="#nav-contact2" role="tab" aria-controls="nav-contact2" aria-selected="false">Comfort &amp; Convenience</a>
                                        <a className="nav-item nav-link" id="nav-contact-tab3" data-toggle="tab" href="#nav-contact3" role="tab" aria-controls="nav-contact3" aria-selected="false">Safety and Security</a>
                                        <a className="nav-item nav-link" id="nav-contact-tab4" data-toggle="tab" href="#nav-contact4" role="tab" aria-controls="nav-contact4" aria-selected="false">Interior</a>
                                        <a className="nav-item nav-link" id="nav-contact-tab5" data-toggle="tab" href="#nav-contact5" role="tab" aria-controls="nav-contact5" aria-selected="false">Exterior</a>
                                        <a className="nav-item nav-link" id="nav-contact-tab6" data-toggle="tab" href="#nav-contact6" role="tab" aria-controls="nav-contact6" aria-selected="false">Safety</a>
                                        <a className="nav-item nav-link" id="nav-contact-tab7" data-toggle="tab" href="#nav-contact7" role="tab" aria-controls="nav-contact7" aria-selected="false">Entertainment</a>
                                        <a className="nav-item nav-link" id="nav-contact-tab8" data-toggle="tab" href="#nav-contact8" role="tab" aria-controls="nav-contact8" aria-selected="false">Auto Checkup</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="right_spec">
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div className="spec_information nice_scroll">
                                                <h4>Performance &amp; Efficiency</h4>
                                                <ul className="nav flex-column">
                                                    <li>Alloy Wheels <span>600.7bhp</span></li>
                                                    <li>Engine Start Stop Button </li>
                                                    <li>Fuel Type <span>Petrol</span></li>
                                                    <li>Mileage (ARAI) <span>5620</span></li>
                                                    <li>Front Suspension <span>12</span></li>
                                                    <li>Steering Type <span>Gear</span></li>
                                                    <li>Steering Column <span>15</span></li>
                                                    <li>Steering Gear Type </li>
                                                    <li>Front Brake Type <span>ABS</span></li>
                                                    <li>Length (mm) <span>5000 mm</span></li>
                                                    <li>Width (mm) <span>2400 mm</span></li>
                                                    <li>ARAI Mileage <span>20 kmh</span></li>
                                                    <li>Max Torque (nm@rpm) <span>1400 nm</span></li>
                                                    <li>TransmissionType <span>Auto</span></li>
                                                    <li>Body Type <span>SUV</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                            <div className="spec_information nice_scroll">
                                                <h4>Audio System</h4>
                                                <ul className="nav flex-column">
                                                    <li>Front Right Tweeter <span> Speaker</span></li>
                                                    <li>Front Door Speaker <span> Subwoofer</span></li>
                                                    <li>Under Seat Speaker <span> Mid-woofer</span></li>
                                                    <li>Rear Door Right Tweeter <span> Speaker</span></li>
                                                    <li>Rear Door Right Speaker <span> Subwoofer</span></li>
                                                    <li>Bass System <img src="images/green.jpg" alt /></li>
                                                    <li>Dual Channel Speaker <img src="images/green.jpg" alt /></li>
                                                    <li>Bluetooth Speaker <img src="images/green.jpg" alt /></li>
                                                    <li>iPad <img src="images/green.jpg" alt /></li>
                                                    <li>Digital Screen <img src="images/green.jpg" alt /></li>
                                                    <li>Factory <span> OE Amplifier</span></li>
                                                    <li>Rear Door Left Speaker<span>Speaker</span></li>
                                                    <li>Rear Door Left Tweeter <span> Subwoofer</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                            <div className="spec_information nice_scroll">
                                                <h4>Instrumentation &amp; Controls</h4>
                                                <ul className="nav flex-column">
                                                    <li>Rear wheel suspension <span>Multi-link</span></li>
                                                    <li>Front suspension <span>Air / Pneumatic</span></li>
                                                    <li>Rear stabilizer <img src="images/green.jpg" alt /></li>
                                                    <li>Front tire size <span>275/50R20</span></li>
                                                    <li>Turning radius <span>13,0 m</span></li>
                                                    <li>Brake assist <img src="images/green.jpg" alt /></li>
                                                    <li>ABS <img src="images/green.jpg" alt /></li>
                                                    <li>1st gear <span>5,25:1</span></li>
                                                    <li>2nd gear <span>3,36:1</span></li>
                                                    <li>3rd gear <span>2,17:1</span></li>
                                                    <li>4th gear <span>3,64:1</span></li>
                                                    <li>5th gear <span>4,44:1</span></li>
                                                    <li>6th gear <span>2,50:1</span></li>
                                                    <li>RPM at 120 km/h <span>1.900 rpm</span></li>
                                                    <li>Final transmission <span>3,64:1</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="nav-contact2" role="tabpanel" aria-labelledby="nav-contact-tab2">
                                            <div className="spec_information nice_scroll">
                                                <h4>Comfort &amp; Convenience</h4>
                                                <ul className="nav flex-column">
                                                    <li>Central locking <img src="img/icon/green.png" alt /></li>
                                                    <li>Keyless entry/start <img src="img/icon/green.png" alt /></li>
                                                    <li>Cruise control <img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Air conditioning <img src="img/icon/green.png" alt /></li>
                                                    <li>Start/stop system <img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Power Steering <img src="img/icon/green.png" alt /></li>
                                                    <li>Power Windows Front <img src="img/icon/green.png" alt /></li>
                                                    <li>Power Windows-Rear <img src="img/icon/green.png" alt /></li>
                                                    <li>Heater <img src="img/icon/green.png" alt /></li>
                                                    <li>Adjustable Steering <img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Automatic Climate Control <img src="img/icon/green.png" alt /></li>
                                                    <li>Air Quality Control <img src="img/icon/green.png" alt /></li>
                                                    <li>Remote Trunk Opener <img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Remote Fuel <img src="img/icon/green.png" alt /></li>
                                                    <li>Low Fuel Warning <img src="img/icon/green.png" alt /></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="nav-contact3" role="tabpanel" aria-labelledby="nav-contact-tab3">
                                            <div className="spec_information nice_scroll">
                                                <h4>Safety and Security</h4>
                                                <ul className="nav flex-column">
                                                    <li>Adjustable Seats <img src="img/icon/green.png" alt /></li>
                                                    <li>Tyre Pressure Monitor<img src="img/icon/green.png" alt /></li>
                                                    <li>Vehicle Stability <img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Control System<img src="img/icon/green.png" alt /></li>
                                                    <li>Engine Immobilizer <img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Crash Sensor <img src="img/icon/green.png" alt /></li>
                                                    <li>Centrally Mounted Fuel Tank <img src="img/icon/green.png" alt /></li>
                                                    <li>Engine Check Warning <img src="img/icon/green.png" alt /></li>
                                                    <li>Automatic Headlamps <img src="img/icon/green.png" alt /></li>
                                                    <li>Clutch Lock <img src="img/icon/close-icon.png" alt /></li>
                                                    <li>EBD <img src="img/icon/green.png" alt /></li>
                                                    <li>Air Quality Control <img src="img/icon/green.png" alt /></li>
                                                    <li>Remote Trunk Opener <img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Remote Fuel <img src="img/icon/green.png" alt /></li>
                                                    <li>Low Fuel Warning <img src="img/icon/green.png" alt /></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="nav-contact4" role="tabpanel" aria-labelledby="nav-contact-tab4">
                                            <div className="spec_information nice_scroll">
                                                <h4>Interior</h4>
                                                <ul className="nav flex-column">
                                                    <li>Tachometer<img src="img/icon/green.png" alt /></li>
                                                    <li>Electronic Multi-Tripmeter<img src="img/icon/green.png" alt /></li>
                                                    <li>Leather Seats<img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Fabric Upholstery<img src="img/icon/green.png" alt /></li>
                                                    <li>Leather Steering Wheel <img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Glove Compartment<img src="img/icon/green.png" alt /></li>
                                                    <li>Digital Clock<img src="img/icon/green.png" alt /></li>
                                                    <li>Outside Temperature Display<img src="img/icon/green.png" alt /></li>
                                                    <li>Cigarette Lighter<img src="img/icon/green.png" alt /></li>
                                                    <li>Digital Odometer<img src="img/icon/close-icon.png" alt /></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="nav-contact5" role="tabpanel" aria-labelledby="nav-contact-tab5">
                                            <div className="spec_information nice_scroll">
                                                <h4>Exterior</h4>
                                                <ul className="nav flex-column">
                                                    <li>Electric Folding Rear View Mirror<img src="img/icon/green.png" alt /></li>
                                                    <li>Rain Sensing Wiper<img src="img/icon/green.png" alt /></li>
                                                    <li>Rear Window Wiper<img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Rear Window Washer<img src="img/icon/green.png" alt /></li>
                                                    <li>Wheel Covers<img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Alloy Wheel Size (Inch)<img src="img/icon/green.png" alt /></li>
                                                    <li>Power Antenna<img src="img/icon/green.png" alt /></li>
                                                    <li>Tinted Glass<img src="img/icon/green.png" alt /></li>
                                                    <li>Rear Spoiler<img src="img/icon/green.png" alt /></li>
                                                    <li>Removable/Convertible Top<img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Roof Carrier<img src="img/icon/green.png" alt /></li>
                                                    <li>Sun Roof<img src="img/icon/close-icon.png" alt /></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="nav-contact6" role="tabpanel" aria-labelledby="nav-contact-tab6">
                                            <div className="spec_information nice_scroll">
                                                <h4>Safety</h4>
                                                <ul className="nav flex-column">
                                                    <li>Anti-Lock Braking System<img src="img/icon/green.png" alt /></li>
                                                    <li>Brake Assist<img src="img/icon/green.png" alt /></li>
                                                    <li>Central Locking<img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Power Door Locks<img src="img/icon/green.png" alt /></li>
                                                    <li>Child Safety Locks<img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Anti-Theft Alarm<img src="img/icon/green.png" alt /></li>
                                                    <li>Driver Airbag<img src="img/icon/green.png" alt /></li>
                                                    <li>Passenger Airbag<img src="img/icon/green.png" alt /></li>
                                                    <li>Side Airbag<img src="img/icon/green.png" alt /></li>
                                                    <li>Front Side Airbag<img src="img/icon/close-icon.png" alt /></li>
                                                    <li>RearDay &amp; Night Rear View Mirror<img src="img/icon/green.png" alt /></li>
                                                    <li>Passenger Side Rear View Mirror<img src="img/icon/green.png" alt /></li>
                                                    <li>Xenon Headlamps<img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Rear Seat Belts<img src="img/icon/green.png" alt /></li>
                                                    <li>Seat Belt Warning<img src="img/icon/green.png" alt /></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="nav-contact7" role="tabpanel" aria-labelledby="nav-contact-tab7">
                                            <div className="spec_information nice_scroll">
                                                <h4>Entertainment</h4>
                                                <ul className="nav flex-column">
                                                    <li>Cd Player<img src="img/icon/green.png" alt /></li>
                                                    <li>CD Changer<img src="img/icon/green.png" alt /></li>
                                                    <li>DVD Player<img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Radio<img src="img/icon/green.png" alt /></li>
                                                    <li>Audio System <img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Remote Control<img src="img/icon/green.png" alt /></li>
                                                    <li>Speakers Front<img src="img/icon/green.png" alt /></li>
                                                    <li>Speakers Rear<img src="img/icon/green.png" alt /></li>
                                                    <li>Integrated 2DIN Audio<img src="img/icon/green.png" alt /></li>
                                                    <li>USB &amp; Auxiliary input<img src="img/icon/close-icon.png" alt /></li>
                                                    <li>Bluetooth Connectivity<img src="img/icon/green.png" alt /></li>
                                                    <li>Touch Screen<img src="img/icon/green.png" alt /></li>
                                                    <li>Internal Storage<img src="img/icon/close-icon.png" alt /></li>
                                                    <li>No of Speakers <span>15</span></li>
                                                    <li>Rear Entertainment System<img src="img/icon/green.png" alt /></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="nav-contact8" role="tabpanel" aria-labelledby="nav-contact-tab8">
                                            <div className="spec_information nice_scroll">
                                                <h4>Auto Checkup</h4>
                                                <ul className="nav flex-column">
                                                    <li>Basic Miles <span>50,000</span></li>
                                                    <li>Basic Years <span>4</span></li>
                                                    <li>Corrosion Miles <span>Unlimited</span></li>
                                                    <li>Corrosion <span>12</span></li>
                                                    <li>Drivetrain Miles <span>50,000</span></li>
                                                    <li>Drivetrain Years <span>4</span></li>
                                                    <li>Hybrid/Electric Components Miles <span>100,000</span></li>
                                                    <li>Hybrid/Electric Components Year <span>8</span></li>
                                                    <li>Maintenance Miles <span>36,000</span></li>
                                                    <li>Maintenance Years <span>6</span></li>
                                                    <li>Roadside Assistance Miles <span>Unlimited</span></li>
                                                    <li>Roadside Assistance Years <span>4</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pr_review" id="review">
                        <div className="single_b_title">
                            <h3>Reviews</h3>
                        </div>
                        <div className="media pr_rate">
                            <div className="media-left">
                                <span>4.8 <i className="fa fa-star" /></span>
                                <p>Based on<br /> 324 user <a href="#">reviews</a></p>
                            </div>
                            <div className="media-body">
                                <div className="text">
                                    <h3>Write a review and rate the car</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do them and eiusmod tempor incididunt labore dolorie magna aliqua. Ut enim adoren minim venim</p>
                                </div>
                                <a className="main_btn red popup-with-zoom-anim" href=".rate">Rate now</a>
                            </div>
                        </div>
                    </div>
                    <div className="pr_review_info">
                        <div className="pr_review_item">
                            <div className="star-rating" />
                            <h5>Engine Performace</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do them and eiusmod tempor incididunt labore dolorie magna aliqua. Ut enim adoren minim venim. quis nostrud exercitation. Ullamco laboris nisi ut aliquip ex ea commodo consequat. <a href="#">Read More</a></p>
                            <h6 className="review_author">By Harshit Surve</h6>
                            <div className="post_date">on: Jan 20, 2020</div>
                            <a href="#" className="like"><i className="fa fa-thumbs-up" aria-hidden="true" /> 5 likes</a>
                        </div>
                        <div className="pr_review_item">
                            <div className="star-rating" />
                            <h5>Design</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do them and eiusmod tempor incididunt labore dolorie magna aliqua. Ut enim adoren minim venim. quis nostrud exercitation. Ullamco laboris nisi ut aliquip ex ea commodo consequat. <a href="#">Read More</a></p>
                            <h6 className="review_author">By Harshit Surve</h6>
                            <div className="post_date">on: Jan 20, 2020</div>
                            <a href="#" className="like"><i className="fa fa-thumbs-up" aria-hidden="true" /> 5 likes</a>
                        </div>
                        <div className="pr_review_item">
                            <div className="star-rating" />
                            <h5>Mileage</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do them and eiusmod tempor incididunt labore dolorie magna aliqua. Ut enim adoren minim venim. quis nostrud exercitation. Ullamco laboris nisi ut aliquip ex ea commodo consequat. <a href="#">Read More</a></p>
                            <h6 className="review_author">By Harshit Surve</h6>
                            <div className="post_date">on: Jan 20, 2020</div>
                            <a href="#" className="like"><i className="fa fa-thumbs-up" aria-hidden="true" /> 5 likes</a>
                        </div>
                        <div className="pr_review_item">
                            <div className="star-rating" />
                            <h5>Pricing</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do them and eiusmod tempor incididunt labore dolorie magna aliqua. Ut enim adoren minim venim. quis nostrud exercitation. Ullamco laboris nisi ut aliquip ex ea commodo consequat. <a href="#">Read More</a></p>
                            <h6 className="review_author">By Harshit Surve</h6>
                            <div className="post_date">on: Dec 20, 2019</div>
                            <a href="#" className="like"><i className="fa fa-thumbs-up" aria-hidden="true" /> 5 likes</a>
                        </div>
                        <div className="row pr_footer">
                            <div className="col-lg-8">
                                <ul className="list-unstyled">
                                    <li><a className="prev page-numbers" href="#"><i className="ti-angle-left" /></a></li>
                                    <li><span aria-current="page" className="page-numbers current">1</span></li>
                                    <li><a className="page-numbers" href="#">2</a></li>
                                    <li><a className="page-numbers" href="#">3</a></li>
                                    <li><a className="page-numbers" href="#">4</a></li>
                                    <li><a className="page-numbers" href="#">5</a></li>
                                    <li><a className="next page-numbers" href="#"><i className="ti-angle-right" /></a></li>
                                </ul>
                                <p>Page 1 of 39</p>
                            </div>
                            <div className="col-lg-4 text-right">
                                <a className="main_btn red popup-with-zoom-anim" href=".rate">Write a Review</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
