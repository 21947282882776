import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Header() {

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('sidenav-open');
        } else {
            document.body.classList.remove('sidenav-open');
        }
        return () => document.body.classList.remove('sidenav-open');
    }, [isOpen]);

    const openNav = () => setIsOpen(true);
    const closeNav = () => setIsOpen(false);


    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const handleMouseEnter = () => setIsMenuOpen(true);
    const handleMouseLeave = () => setIsMenuOpen(false);


    return (
        <div>
            <header>
                <div className="topSec wow fadeInLeft" data-wow-duration="2s">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 col-sm-3 col-xs-12 col-lg-2">
                                <div className="logo-img">
                                    <Link to="/"><img src="images/logo.png" alt="img" /></Link>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-8 col-sm-3 col-xs-12">
                                <div className="input-btn">
                                    <form>
                                        <input type="text" name="text" placeholder="Search anything..." />
                                        <button type='submit'><i className="fas fa-search" /></button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-4 col-lg-2">
                                <div className="ankar">
                                    <ul>
                                        <li>
                                            <Link to="/signup">
                                                <i className="far fa-user" />
                                                <h5>SIGN UP</h5>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="menuSec wow fadeInRight" data-wow-duration="2s">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-sm-6 col-xs-6">
                                <div className="header-category">
                                    <ul>
                                        <li className="pr_mega" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <Link to="javscript:;">CATEGORIES
                                                {
                                                    isMenuOpen === true ?
                                                        <i className="fas fa-chevron-up" />
                                                        : <i className="fas fa-chevron-down" />
                                                }

                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-9 col-lg-9">
                                <div className="nav-head">
                                    <span onClick={openNav}>
                                        <i className="fa-solid fa-bars" />
                                    </span>
                                    <div className="sidenav" style={{ transform: isOpen ? 'translate(0px, 0px)' : 'translate(110%, 0px)' }}>
                                        <Link to="javascript:void(0)" className="closebtn" onClick={closeNav}>×</Link>
                                        <Link to="/" onClick={closeNav}>HOME</Link>
                                        <Link to="/products" onClick={closeNav}>PRODUCTS</Link>
                                        <Link to="/advertisement" onClick={closeNav}>ADVERTISEMENT</Link>
                                        <Link to="/contact" onClick={closeNav}> CONTACT US</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="prd_mega_menu" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ display: isMenuOpen ? 'block' : 'none' }}>
                    <div className='container'>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-12">
                                <div className="abc_lis">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3">
                                            <div className="nav-underlist">
                                                <h5>Property</h5>
                                                <Link to="">lorem ipsum</Link>
                                                <Link to="">lorem ipsum</Link>
                                                <Link to="">lorem ipsum</Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <div className="nav-underlist">
                                                <h5>Services</h5>
                                                <Link to="">lorem ipsum</Link>
                                                <Link to="">lorem ipsum</Link>
                                                <Link to="">lorem ipsum</Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <div className="nav-underlist">
                                                <h5>Vehicles</h5>
                                                <Link to="">lorem ipsum</Link>
                                                <Link to="">lorem ipsum</Link>
                                                <Link to="">lorem ipsum</Link>
                                                <Link to="">lorem ipsum</Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3">
                                            <div className="nav-underlist">
                                                <h5>Jobs</h5>
                                                <Link to="">lorem ipsum</Link>
                                                <Link to="">lorem ipsum</Link>
                                                <Link to="">lorem ipsum</Link>
                                                <Link to="">lorem ipsum</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div >
    )
}
