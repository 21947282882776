import React from 'react';
import { Link } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';

export default function Products() {

    return (
        <div>
            <section className="banner inn">
                <div className="container">
                    <div className="bann-slid wow fadeInRightBig" data-wow-duration="2s">
                        <div>
                            <div className="row align-items-center">
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="bann-txt wow fadeInLeftBig" data-wow-duration="2s">
                                        <h2>PRODUCT</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsumet, consectetur</p>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-12">
                                    <div className="banner-img">
                                        <img src="images/banner-img.png" alt />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="head-3">
                        <h3>TESTLA</h3>
                    </div>
                </div>
            </section>
            <section className="product-inner-sec all-section">
                <div className="container themes-container">
                    <div className="row align-items-start">
                        <div className="col-lg-3 col-md-3 col-12">
                            <div className="cate-main-bxinn">
                                <h4>Categories</h4>
                                <p>Vehicles</p>
                                <ul>
                                    <li><Link to=""> Buses &amp; Microbuses <span>| 876</span></Link></li>
                                    <li><Link to=""> Heavy Equipment <span>| 604</span></Link></li>
                                    <li><Link to=""> Motorbikes &amp; Scooters <span>| 1927</span></Link></li>
                                    <li><Link to=""> Trucks &amp; Trailers<span>| 1072</span></Link></li>
                                    <li><Link to=""> Vehicle Parts &amp; Accessories <span>| 68572</span></Link></li>
                                    <li><Link to=""> Watercraft &amp; Boats <span>| 27</span></Link></li>
                                </ul>
                            </div>
                            <div className="loc">
                                <input type="name" name="name" placeholder="Location" />
                            </div>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Price, GH₵
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div className="price-renge">
                                                <div className="price-in">
                                                    <input type="name" name="name" placeholder="min" />
                                                    <input type="name" name="name" placeholder="max" />
                                                </div>
                                                <ul>
                                                    <li><input type="radio" name="radio" /><p>Under 200 . 5 265 ads</p></li>
                                                    <li><input type="radio" name="radio" /><p>Under 200-700 . 5 265 ads</p></li>
                                                    <li><input type="radio" name="radio" /><p>Under 710-5k . 5 265 ads</p></li>
                                                    <li><input type="radio" name="radio" /><p>Under 5-450k . 5 265 ads</p></li>
                                                    <li><input type="radio" name="radio" /><p>more than 450k . 5 265 ads</p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Discount
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div className="discount-txt">
                                                <ul>
                                                    <li><input type="radio" name="radio" /><p>Show all</p></li>
                                                    <li><input type="radio" name="radio" /><p>With discount.20 ads</p></li>
                                                    <li><input type="radio" name="radio" /><p>Without discount.21 894 ads</p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Verified Sellers
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <div className="discount-txt">
                                                <ul>
                                                    <li><input type="radio" name="radio" /><p>Show all</p></li>
                                                    <li><input type="radio" name="radio" /><p>Verified Sellers.8 400 ads</p></li>
                                                    <li><input type="radio" name="radio" /><p>Unverified Sellers. 13 514 ads</p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="main-car-bx">
                                        <div className="car-img">
                                            <Link to="/post-add"><img src="images/car-1.png" alt /></Link>
                                        </div>
                                        <div className="car-txt">
                                            <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                            <ul>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                            </ul>
                                            <div className="check">
                                                <i className="fas fa-check" />
                                                <h4>AVAILABLE</h4>
                                            </div>
                                            <Link to="#" className="btn-1">GET A QUOTE</Link>
                                            <p>SKU:<span>30876</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="main-car-bx">
                                        <div className="car-img">
                                            <Link to="/post-add"><img src="images/car-2.png" alt /></Link>
                                        </div>
                                        <div className="car-txt">
                                            <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                            <ul>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                            </ul>
                                            <div className="check">
                                                <i className="fas fa-check" />
                                                <h4>AVAILABLE</h4>
                                            </div>
                                            <Link to="#" className="btn-1">GET A QUOTE</Link>
                                            <p>SKU:<span>30876</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="main-car-bx">
                                        <div className="car-img">
                                            <Link to="/post-add"><img src="images/car-3.png" alt /></Link>
                                        </div>
                                        <div className="car-txt">
                                            <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                            <ul>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                            </ul>
                                            <div className="check">
                                                <i className="fas fa-check" />
                                                <h4>AVAILABLE</h4>
                                            </div>
                                            <Link to="#" className="btn-1">GET A QUOTE</Link>
                                            <p>SKU:<span>30876</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="main-car-bx">
                                        <div className="car-img">
                                            <Link to="/post-add"><img src="images/car-1.png" alt /></Link>
                                        </div>
                                        <div className="car-txt">
                                            <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                            <ul>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                            </ul>
                                            <div className="check">
                                                <i className="fas fa-check" />
                                                <h4>AVAILABLE</h4>
                                            </div>
                                            <Link to="#" className="btn-1">GET A QUOTE</Link>
                                            <p>SKU:<span>30876</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="main-car-bx">
                                        <div className="car-img">
                                            <Link to="/post-add"><img src="images/car-2.png" alt /></Link>
                                        </div>
                                        <div className="car-txt">
                                            <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                            <ul>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                            </ul>
                                            <div className="check">
                                                <i className="fas fa-check" />
                                                <h4>AVAILABLE</h4>
                                            </div>
                                            <Link to="#" className="btn-1">GET A QUOTE</Link>
                                            <p>SKU:<span>30876</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="main-car-bx">
                                        <div className="car-img">
                                            <Link to="/post-add"><img src="images/car-5.png" alt /></Link>
                                        </div>
                                        <div className="car-txt">
                                            <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                            <ul>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                            </ul>
                                            <div className="check">
                                                <i className="fas fa-check" />
                                                <h4>AVAILABLE</h4>
                                            </div>
                                            <Link to="#" className="btn-1">GET A QUOTE</Link>
                                            <p>SKU:<span>30876</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-8">
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="main-car-bx">
                                        <div className="car-img">
                                            <Link to="/post-add"><img src="images/car-6.png" alt /></Link>
                                        </div>
                                        <div className="car-txt">
                                            <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                            <ul>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                            </ul>
                                            <div className="check">
                                                <i className="fas fa-check" />
                                                <h4>AVAILABLE</h4>
                                            </div>
                                            <Link to="#" className="btn-1">GET A QUOTE</Link>
                                            <p>SKU:<span>30876</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="main-car-bx">
                                        <div className="car-img">
                                            <Link to="/post-add"><img src="images/car-7.png" alt /></Link>
                                        </div>
                                        <div className="car-txt">
                                            <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                            <ul>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                            </ul>
                                            <div className="check">
                                                <i className="fas fa-check" />
                                                <h4>AVAILABLE</h4>
                                            </div>
                                            <Link to="#" className="btn-1">GET A QUOTE</Link>
                                            <p>SKU:<span>30876</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="main-car-bx">
                                        <div className="car-img">
                                            <Link to="/post-add"><img src="images/car-8.png" alt /></Link>
                                        </div>
                                        <div className="car-txt">
                                            <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                            <ul>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                            </ul>
                                            <div className="check">
                                                <i className="fas fa-check" />
                                                <h4>AVAILABLE</h4>
                                            </div>
                                            <Link to="#" className="btn-1">GET A QUOTE</Link>
                                            <p>SKU:<span>30876</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="main-car-bx">
                                        <div className="car-img">
                                            <Link to="/post-add"><img src="images/car-1.png" alt /></Link>
                                        </div>
                                        <div className="car-txt">
                                            <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                            <ul>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                            </ul>
                                            <div className="check">
                                                <i className="fas fa-check" />
                                                <h4>AVAILABLE</h4>
                                            </div>
                                            <Link to="#" className="btn-1">GET A QUOTE</Link>
                                            <p>SKU:<span>30876</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="main-car-bx">
                                        <div className="car-img">
                                            <Link to="/post-add"><img src="images/car-2.png" alt /></Link>
                                        </div>
                                        <div className="car-txt">
                                            <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                            <ul>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                            </ul>
                                            <div className="check">
                                                <i className="fas fa-check" />
                                                <h4>AVAILABLE</h4>
                                            </div>
                                            <Link to="#" className="btn-1">GET A QUOTE</Link>
                                            <p>SKU:<span>30876</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4 col-12">
                                    <div className="main-car-bx">
                                        <div className="car-img">
                                            <Link to="/post-add"><img src="images/car-3.png" alt /></Link>
                                        </div>
                                        <div className="car-txt">
                                            <h5>PRODUCTS NAME HERE<span>CARS MODEL</span></h5>
                                            <ul>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                                <li><Link to="#"><i className="fas fa-star" /></Link></li>
                                            </ul>
                                            <div className="check">
                                                <i className="fas fa-check" />
                                                <h4>AVAILABLE</h4>
                                            </div>
                                            <Link to="#" className="btn-1">GET A QUOTE</Link>
                                            <p>SKU:<span>30876</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
